<template>
  <VCard v-if="invitations.length > 0">
    <VCardText>
      <VList
        v-for="(invitation, key) in invitations"
        class="pb-2"
        :key="key"
      >
        <VListItem class="pa-0">
          <VListItemContent class="pa-0">
            <VAlert
              color="#ffe79a"
              class="mb-0 body-2"
            >
              <span v-if="invitation.into_programs === '{}'">
                You've been invited to <strong>{{ invitation.team.name }}</strong> as <em>{{ getRoleName(invitation) }}</em>
              </span>
              <span v-else>
                <strong>{{ invitation.team.name }}</strong> has invited you to join {{ featureName("Program").singularize() }} <strong>{{ getInvitedProgramTitle(invitation) }}</strong>
              </span>
            </VAlert>
          </VListItemContent>
        </VListItem>
        <VListItem class="px-0">
          <!-- Team Name -->
          <VListItemContent class="pa-0">
            <VCol class="pl-0">
              <img
                style="height: 34px"
                :src="invitation.team.photo_url"
              >
            </VCol>
            <VCol class="text-right pr-0">
              <VTooltip bottom>
                <template #activator="{on}">
                  <VBtn
                    v-on="on"
                    class="mr-3"
                    :disabled="invitation.isAccepting"
                    :loading="invitation.isRejecting"
                    @click="reject(invitation)"
                  >
                    Reject
                  </VBtn>
                </template>
                <span>Reject this invitation</span>
              </VTooltip>
              <VTooltip bottom>
                <template #activator="{on}">
                  <VBtn
                    color="success"
                    v-on="on"
                    :disabled="invitation.isRejecting"
                    :loading="invitation.isAccepting"
                    :style="{
                      backgroundColor: '#a3e36d !important',
                      borderRadius: '10px',
                      boxShadow: '0px 4px 4px 0 rgba(163, 227, 109, 0.45)'
                    }"
                    @click="accept(invitation)"
                  >
                    Accept
                  </VBtn>
                </template>
                <span>Accept this invitation</span>
              </VTooltip>
            </VCol>
          </VListItemContent>
        </VListItem>
      </VList>
    </VCardText>
  </VCard>
</template>

<script>
import { mapActions } from "vuex";
export default {
  /**
   * The component's data.
   */
  data() {
    return {
      invitations: [],
    };
  },

  /**
   * The component has been created by Vue.
   */
  created() {
    this.getPendingInvites();
  },

  methods: {
    ...mapActions({
      doAcceptInvite: "team/acceptInvite",
      doRejectInvite: "team/rejectInvite",
      doGetPendingInvitations: "team/getPendingInvites",
    }),
    /**
     * Get the pending invitations for the user.
     */
    getPendingInvites() {
      this.doGetPendingInvitations().then((invites) => {
        this.invitations = invites;
      });
    },

    /**
     * Accept the given invitation.
     */
    accept(invitation) {
      this.$set(invitation, "isAccepting", true);
      this.doAcceptInvite(invitation.id).then((team) => {
        if(invitation.into_programs == "{}") {
          this.$log.debug("Accepted invite for team", team);
          let teamIndex = this.$user.teams.findIndex((team) => team.id === this.$team.id);
          if(teamIndex === -1) {
            this.$user.teams.push(team);
          }
          this.$user.invitations_count -= 1;
          this.$user.invitations_count = this.$user.invitations_count || 0;
          this.getPendingInvites();
          this.$emit("accepted", invitation);
        } else {
          window.location.reload();
        }
      });
    },

    /**
     * Reject the given invitation.
     */
    reject(invitation) {
      this.$set(invitation, "isRejecting", true);
      this.doRejectInvite(invitation.id).then((result) => {
        this.getPendingInvites();
        this.$emit("rejected", invitation);
        this.$bus.$emit(
          "notificationMessage",
          `You've declined the invite!`
        );
      });
    },

    /**
     * Remove the given invitation from the list.
     */
    removeInvitation(invitation) {
      this.invitations = _.reject(
        this.invitations,
        (i) => i.id === invitation.id
      );
    },

    getRoleName(invitation) {
      let role = invitation.role;
      role = invitation.team.roles.find(
        (value, index) => value.id == invitation.role
      );
      return role instanceof Object ? role.name : "";
    },
    getInvitedProgramTitle(invitation) {
      let programs = JSON.parse(invitation.into_programs);
      return programs.length > 1 ? programs[programs.length - 1].title : programs[0].title;
    },
  },
};
</script>
