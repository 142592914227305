<script>
export default {
  data() {
    return {
      notificationTimeout: 5000,
      errors: [],
      messages: [],
    };
  },
  computed: {
    hasMessagesOrErrors() {
      return (
        (this.messages && this.messages.length > 0) ||
        (this.errors && this.errors.length > 0)
      );
    },
  },
  watch: {
    errors: function (value) {
      if (value instanceof Array && value.length) {
        this.errorSnackbar = true;
      }
    },
    messages: function (value) {
      if (value instanceof Array && value.length) {
        this.snackbar = true;
      }
    },
  },
  created() {
    this.$bus.$on("notificationError", (error) => {
      this.$log.debug("[Nofification Error]", error);
      this.errors = error instanceof Array ? error : [error];
      // setTimeout(() => (this.errors = []), this.notificationTimeout);
    });
    this.$bus.$on("notificationMessage", (message) => {
      this.$log.debug("[Nofification Message]", message);
      this.messages = message instanceof Array ? message : [message];
      setTimeout(() => (this.messages = []), this.notificationTimeout);
    });
  },
  methods: {},
};
</script>