<template>
  <section
    class="register-member-form"
    mt-2
  >
    <VAlert
      v-if="invite"
      :value="true"
      type="info"
    >
      Congratulations. You've been invited to join
      <strong>{{ invite.team.name }}</strong>!
    </VAlert>
    <template v-if="currentTeam">
      <SmallTeamBanner :team="currentTeam" />
    </template>
    <form
      class="form"
      name="login"
      :disabled="activity.isFormLoading"
      @submit.prevent="register"
    >
      <VAlert
        v-if="!teamSlug"
        type="info"
        class="mt-3"
        :value="!teamExists"
      >
        Enter the team name
      </VAlert>
      <TextField
        v-if="!invite"
        v-model="teamSlug"
        label="Team Name"
        name="teamSlug"
        type="text"
        :disabled="isSearchingTeam"
        @keyup.enter="findTeam"
      >
        <VBtn
          slot="append"
          icon
          :loading="isSearchingTeam"
          @click.prevent="findTeam"
        >
          <VIcon>search</VIcon>
        </VBtn>
      </TextField>
      <template v-if="canRegister">
        <Alerts
          v-if="hasMessagesOrErrors"
          :messages="messages"
          :errors="errors"
        />
        <TextField
          v-model="form.name"
          label="Name"
          type="text"
          hint="Please enter your name"
          :disabled="isLoading || !teamExists"
          :error="$v.form.name.$error"
          :persistent-hint="$v.form.name.$error"
          @input="$v.$touch()"
        />
        <TextField
          v-model="form.email"
          label="Email"
          type="email"
          hint="Please enter your email"
          :disabled="isLoading || !teamExists"
          :error="$v.form.email.$error"
          :persistent-hint="$v.form.email.$error"
          @input="$v.$touch()"
        />
        <PasswordInput
          v-model="form.password"
          :editable="false"
          :disabled="isLoading || !teamExists"
          :error="$v.form.password.$error"
          @input="$v.$touch()"
        />
        <PasswordInput
          v-if="form.password"
          v-model="form.password_confirmation"
          label="Confirm Password"
          :editable="false"
          :disabled="isLoading || !teamExists"
          :error="$v.form.password_confirmation.$error"
          @input="$v.$touch()"
        />
        <h3 class="title">
          What role are you registering as?
        </h3>
        <VRadioGroup
          v-model="form.role"
          :error="$v.form.role.$error"
          row
        >
          <VRadio
            v-for="(role, key) in options.roles"
            :key="key"
            :label="featureName(role.name, null, null, currentTeam)"
            :value="role.id"
          />
        </VRadioGroup>
        <template
          v-if="
            hasOnboardingForm &&
              form.current_team_onboarding_form &&
              form.current_team_onboarding_form.metadata
          "
        >
          <DynamicInputsSubmit
            title="Please answer the following questions"
            v-model="form.current_team_onboarding_form.metadata"
            input-name="Onboarding Question"
            @update:input="
              (key, value) =>
                onUpdateMetadata(
                  key,
                  value,
                  'metadata',
                  'form.current_team_onboarding_form'
                )
            "
          />
        </template>

        <VCheckbox
          v-model="form.agreement"
          type="checkbox"
          label="Check here to agree to our"
          :error="$v.form.agreement.$error"
        />
        <VBtn
          text
          small
          color="secondary"
          @click="dialogs.terms = true"
        >
          Terms and Conditions
        </VBtn>&nbsp; and &nbsp;
        <VBtn
          text
          small
          color="secondary"
          @click="dialogs.privacy = true"
        >
          Privacy Policy
        </VBtn>
        <VBtn
          block
          class="mt-2"
          color="success"
          :class="submitClass"
          :disabled="!isFormReady || activity.isFormLoading"
          @click.prevent="register"
        >
          {{ submitText }}
        </VBtn>
      </template>
    </form>
    <Dialog
      :is-dialog-open="dialogs.privacy"
      title="Privacy Policy"
      @toggle:dialog="() => (dialogs.privacy = false)"
    >
      <Privacy mode="dialog" />
    </Dialog>
    <Dialog
      :is-dialog-open="dialogs.terms"
      title="Terms and Conditions"
      @toggle:dialog="() => (dialogs.terms = false)"
    >
      <Terms mode="dialog" />
    </Dialog>
  </section>
</template>

<script>
import { AUTH_REQUEST, REGISTER_REQUEST } from "@/store/actionTypes";
import {
  sameAs,
  email,
  required,
  minLength,
  between,
  requiredIf,
} from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import FormMixin from "@/mixins/Form";
import NeedsTeamsMixin from "@/mixins/NeedsTeams";
import DynamicInputsSubmit from "@/components/Elements/Forms/DynamicInputsSubmit";
import HasMetadataMixin from "@/mixins/Metadata";
import Privacy from "@/components/App/Privacy";
import Terms from "@/components/App/Terms";
import PasswordInput from "@/components/Elements/Forms/PasswordInput";

export default {
  name: "RegisterMemberForm",
  mixins: [NeedsTeamsMixin, FormMixin, HasMetadataMixin],
  components: { DynamicInputsSubmit, Privacy, Terms, PasswordInput },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    invite: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      error: false,
      submitClass: "wethrive",
      options: {
        roles: [],
      },
      dialogs: {
        privacy: false,
        terms: false,
      },
      defaultForm: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role: "",
        team_id: null,
        terms: true,
        current_team_onboarding_form: {
          metadata: [],
        },
        agreement: null,
        pipeline_application: {
          type: "application-pipeline",
        },
      },
      isLoading: false,
      modelType: "Registration",
      findTeamScope: "register",
    };
  },
  created() {
    /**
     * Saw a situaton where there is an access token but it isn't valid
     * Logging out user here in that case
     */
    this.doLogout();
  },
  validations: {
    teamSlug: {
      minLength: minLength(3),
    },
    form: {
      name: {
        required,
      },
      agreement: {
        sameAs: sameAs(() => true),
      },
      password: {
        required,
      },
      password_confirmation: {
        sameAsPassword: sameAs("password"),
      },
      email: {
        email,
        required,
      },
      team_id: {
        required,
      },
      role: {
        required,
      },
      current_team_onboarding_form: {
        required: requiredIf(function(nestedModel) {
          return this.hasOnboardingForm === true;
        }),
        metadata: {
          required: requiredIf(function(nestedModel) {
            return this.hasOnboardingForm === true;
          }),
          $each: {
            name: { required },
            value: { required },
          },
        },
      },
    },
  },
  computed: {
    submitText() {
      if (this.invite) {
        return this.isLoading ? "Accepting invite..." : "Accept Invite";
      } else {
        return this.isLoading ? "Requesting access..." : "Request Acccess";
      }
    },
  },
  watch: {
    currentTeam: function(team) {
      if (team && !this.invite) {
        if (team.open_registration) {
          this.form.team_id = team.id;
          this.options.roles = team.signup_roles;
          this.isRegistrationDisabled = false;
          this.form.current_team_onboarding_form = {
            team_id: team.id,
            metadata: this.getMetadataForSubmit(team.onboarding_form),
          };
        } else {
          this.isRegistrationDisabled = true;
          this.errors = [
            `Registration is disabled for ${
              team.name
            }. Please contact <a style="color: #000" href="mailto:${
              team.email
            }">${team.email}</a> for help signing up`,
          ];
        }
      }
    },
    invite: function(invite) {
      if (invite instanceof Object) {
        this.prepareInvite();
      }
    },
  },
  methods: {
    ...mapActions({
      doRegister: "register/register",
      doLogin: "auth/login",
      doLogout: "auth/logout",
    }),
    register() {
      this.form.team = this.currentTeam ? this.currentTeam.name : null;
      const password = this.form.password;
      return this.saveFormModel(this.doRegister)
        .then((resp) => {
          if (resp.status != "error") {
            const { username = this.form.email } = this.form;
            this.activity.isFormLoading = true;
            // Make sure the user is logged out before logging in
            this.doLogout().then(() => {
              this.doLogin({
                username,
                password,
              }).then((result) => {
                this.isLoading = false;
                this.submitClass = "wethrive-alt";
                this.activity.isFormLoading = false;
                this.$log.debug(
                  "RegisterMemberForm.register: Registered ... waiting for user"
                );
                this.$bus.$emit(
                  "notificationMessage",
                  "Registration was successful!"
                );
                // Launch WeThrive app if user accept the invitation from Mobile device 
                if(/webOS|iPhone|iPad|iPod|BlackBerry|Android/i.test(navigator.userAgent) && this.$route.query.invitation) {
                  if(resp.team.slug == "wethrive-education") {
                    window.location.href = `https://wethrive-yarn.onelink.me/PESd/fdvpol90?action=login&id=${result.data.access_token}`;
                  } else if(resp.team.slug == "dycd") {
                    window.location.href = `https://wethrive-dycd.onelink.me/ENRE/fr5mc8qo?action=login&id=${result.data.access_token}`;
                  } else {
                    window.location.href = `https://wethrive.onelink.me/7Zg8/7sckelno?action=login&id=${result.data.access_token}`;
                  }
                } else {
                  const ready = function() {
                    this.$log.debug(
                      "RegisterMemberForm.login: User is available"
                    );
                    window.location.href = `${
                      this.$team ? "/" + this.$team.slug : ""
                    }/app/dashboard`;
                    this.$router.push({
                      name: "dashboard",
                      params: {
                        team: this.$team.slug,
                      },
                    });
                  };
  
                  setTimeout(() => {
                    if (!this.$auth.ready()) {
                      this.$auth.ready(ready);
                    } else {
                      ready.call(this);
                    }
                  }, 500);
                }
              });
            });
          } else {
            this.errors = resp.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    prepareInvite() {
      this.currentTeam = this.invite.team;
      this.form.email = this.invite.email;
      this.form.team_id = this.currentTeam.id;
      this.form.role = this.invite.role;
      this.form.invitation = this.invite.token;
      this.form.name = this.invite.name;
      this.options.roles = [
        {
          id: this.invite.role,
          name: this.invite.role.titleize(),
        },
      ];
      if(this.invite.pipeline_id) {
        this.form.pipeline_application.pipeline_id = this.invite.pipeline_id;
        this.form.pipeline_application.current_stage_id = this.invite.pipeline_stage_id;
      }
      this.teamSlug = this.invite.team.slug;
      // this.validateModelAndSetErrors();
    },
  },
  created() {
    if (this.invite) {
      this.prepareInvite();
    }
    if (this.$route.params.team) {
      this.teamSlug = this.$route.params.team;
    }
  },
};
</script>
