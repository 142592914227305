<template>
  <section class="register-member-form">
    <Alerts
      v-if="hasMessagesOrErrors"
      :messages="messages"
      :errors="errors"
    />
    <VAlert
      v-if="currentTeam"
      :value="true"
      class="info"
    >
      <SmallTeamBanner :team="currentTeam" />
    </VAlert>
    <form
      class="form"
      name="login"
      @submit.prevent
    >
      <VAlert
        v-if="!teamSlug"
        type="info"
        :value="!teamExists"
      >
        Enter the team name
      </VAlert>
      <VAlert
        v-if="userWasCurrentTeamMember && userIsCurrentTeamMember"
        type="warning"
        :value="true"
      >
        You're already a member of this team!
      </VAlert>
      <TextField
        v-if="!isSuccessFullOpenRegistration && !needsConfirmation"
        v-model="teamSlug"
        label="Team Name"
        name="teamSlug"
        type="text"
        :disabled="isSearchingTeam"
        @keyup.enter="findTeam"
      >
        <VBtn
          slot="append"
          icon
          :loading="isSearchingTeam"
          @click.prevent="findTeam"
        >
          <VIcon>search</VIcon>
        </VBtn>
      </TextField>
      <VAlert
        v-if="needsConfirmation"
        type="info"
        :value="true"
      >
        You've successfully applied to {{ currentTeam.name }}. You'll receive a confirmation email once your
        application has been approved.
        <VBtn @click="$emit('close')">
          Click here to continue
        </VBtn>
      </VAlert>
      <VAlert
        v-else-if="isSuccessFullOpenRegistration"
        type="success"
        :value="true"
      >
        <h2>Welcome to {{ currentTeam.name }}!</h2>
        <VBtn @click="$emit('close')">
          Click here to continue
        </VBtn>
      </VAlert>
      <template v-else-if="canRegister">
        <VList subheader>
          <VListItem>
            <VListItemContent>
              <VListItemSubtitle>Your name</VListItemSubtitle>
              <VListItemTitle>{{ userForm.name }}</VListItemTitle>
            </VListItemContent>
          </VListItem>
          <VListItem>
            <VListItemContent>
              <VListItemSubtitle>Your email</VListItemSubtitle>
              <VListItemTitle>{{ userForm.email }}</VListItemTitle>
            </VListItemContent>
          </VListItem>
        </VList>
        <h3 class="title">
          What role are you registering as?
        </h3>
        <VRadioGroup
          v-model="form.role_id"
          row
        >
          <VRadio
            v-for="(role, key) in options.roles"
            :key="key"
            :label="featureName(role.name, null, null, currentTeam)"
            :value="role.id"
          />
        </VRadioGroup>
        <VCheckbox
          v-model="form.agreement"
          type="checkbox"
        />By signing up you agree to our
        <VBtn
          text
          small
          color="secondary"
          @click="dialogs.terms = !dialogs.terms"
        >
          Terms and Conditions
        </VBtn>&nbsp; and &nbsp;
        <VBtn
          text
          small
          color="secondary"
          @click="dialogs.privacy = !dialogs.privacy"
        >
          Privacy Policy
        </VBtn>
        <template v-if="hasOnboardingForm && form.current_team_onboarding_form && form.current_team_onboarding_form.metadata">
          <DynamicInputsSubmit
            title="Please answer the following questions"
            v-model="form.current_team_onboarding_form.metadata"
            input-name="Onboarding Question"
            @update:input="(key, value) => onUpdateMetadata(key, value, 'metadata', 'form.current_team_onboarding_form')"
          />
        </template>
        <VBtn
          block
          color="success"
          class="mt-2"
          :class="submitClass"
          :loading="isLoading"
          :disabled="!isFormReady || isLoading"
          @click.prevent="register"
        >
          {{ submitText }}
        </VBtn>
      </template>
      <template v-else>
        <VAlert>You can't register for this team</VAlert>
      </template>
    </form>
  </section>
</template>

<script>
import { AUTH_REQUEST, REGISTER_REQUEST } from "@/store/actionTypes";
import {
  email,
  required,
  minLength,
  between,
  requiredIf,
} from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import FormMixin from "@/mixins/Form";
import NeedsTeamsMixin from "@/mixins/NeedsTeams";
import DynamicInputsSubmit from "@/components/Elements/Forms/DynamicInputsSubmit";
import HasMetadataMixin from "@/mixins/Metadata";

export default {
  name: "RegisterExistingMemberForm",
  mixins: [NeedsTeamsMixin, FormMixin, HasMetadataMixin],
  components: { DynamicInputsSubmit },
  data() {
    return {
      error: false,
      submitClass: "wethrive",
      options: {
        roles: [],
      },
      defaultForm: {
        name: "",
        email: "",
        password: "",
        role: "",
        team_id: null,
        current_team_onboarding_form: {
          metadata: [],
        },
        pipeline_application: {
          type: "application-pipeline",
        },
      },
      verifyPassword: "",
      isLoading: false,
      isSuccessFullOpenRegistration: false,
      needsConfirmation: false,
      modelType: "Registration",
      findTeamScope: "register",
    };
  },
  validations: {
    teamSlug: {
      minLength: minLength(3),
    },
    form: {
      role_id: {
        required,
      },
      email: { required, email },
      current_team_onboarding_form: {
        required: requiredIf(function (nestedModel) {
          return this.hasOnboardingForm === true;
        }),
        metadata: {
          required: requiredIf(function (nestedModel) {
            return this.hasOnboardingForm === true;
          }),
          $each: {
            name: { required },
            value: { required },
          },
        },
      },
    },
  },
  computed: {
    submitText() {
      return this.isLoading ? "Requesting access..." : "Request Acccess";
    },
  },
  watch: {
    currentTeam: function (team) {
      if (team) {
        if (team.open_registration) {
          this.form.team_id = team.id;
          this.options.roles = team.signup_roles;
          this.isRegistrationDisabled = false;
          this.form.current_team_onboarding_form = {
            team_id: team.id,
            metadata: this.getMetadataForSubmit(team.onboarding_form),
          };
        } else {
          this.isRegistrationDisabled = true;
          this.errors = [
            `Registration is disabled for ${team.name}. Please contact <a style="color: #000" href="mailto:${team.email}">${team.email}</a> for help signing up`,
          ];
        }
      }
    },
  },
  methods: {
    ...mapActions({
      doRequestAccess: "team/requestAccess",
    }),
    register() {
      this.isLoading = true;
      this.errors = null;
      this.doRequestAccess({
        team_id: this.form.team_id,
        role: this.form.role_id,
        id: this.$user.id,
        current_team_onboarding_form: this.form.current_team_onboarding_form,
      })
        .then((resp) => {
          if (resp.id) {
            this.$user.teams.push(resp);
            this.$user.team = this.$user.team || resp;
            if (resp.pivot.is_approved) {
              this.isSuccessFullOpenRegistration = true;
              this.$emit("isSuccessFullOpenRegistration");
            } else {
              this.needsConfirmation = true;
              this.$emit("needsConfirmation");
            }
          } else {
            this.errors = [resp.message];
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = this.parseErrors(error);
          this.$log.debug("Error information", this.errors);
          this.isLoading = false;
        });
    },
  },
};
</script>