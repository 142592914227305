var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VApp',{staticClass:"wethrive",style:(_vm.style)},[(_vm.hasMessagesOrErrors)?_c('Notifications',{attrs:{"messages":_vm.messages,"errors":_vm.errors,"timeout":_vm.notificationTimeout}}):_vm._e(),_c('VRow',{attrs:{"row":"","wrap":"","justify-flex-end":"","fill-height":"","px-3":_vm.$vuetify.breakpoint.mdAndUp && !_vm.fluid,"px-1":_vm.$vuetify.breakpoint.smAndDown && !_vm.fluid,"py-2":!_vm.fluid}},[_c('VCol',{staticClass:"d-flex align-center py-0",attrs:{"xs":_vm.$vuetify.breakpoint.smAndDown || _vm.fluid ? 12 : false}},[_vm._t("default")],2),(_vm.enableFooter)?_c('VCol',{class:{
        'text-sm-center text-md-right': true,
        'd-flex py-0 px-0': true,
        'align-end': true,
        'justify-end': true,
        'footer-section': true
      },attrs:{"cols":12,"fill-height":_vm.$vuetify.breakpoint.mdAndUp && !_vm.fluid}},[_c('div',{class:{
          'px-2 pb-2 pt-0': true,
          'align-center': _vm.$vuetify.breakpoint.smAndDown,
          'd-flex': _vm.$vuetify.breakpoint.smAndDown,
        },style:({
          position: _vm.$vuetify.breakpoint.mdAndUp && !_vm.fluid ? 'fixed' : 'auto',
          right: _vm.$vuetify.breakpoint.mdAndUp ? '0' : 'auto',
        })},[_c('VBtn',{attrs:{"text":"","small":"","href":"https://wethrive.tech/","target":"_blank'"}},[_vm._v(" About WeThrive ")]),_vm._v("  |   "),_c('VBtn',{attrs:{"text":"","small":"","to":{name: 'terms-and-conditions'}}},[_vm._v(" Terms and Conditions ")]),_vm._v("  |   "),_c('VBtn',{attrs:{"text":"","small":"","to":{name: 'privacy-policy'}}},[_vm._v(" Privacy Policy ")])],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }