<template>
  <AppLayout
    fluid
    :enable-background="false"
  >
    <Register />
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AlternateAppLayout";
import Register from "@/components/Register/ForTeam/Form";

export default {
  name: "RegisterForTeam",
  components: {
    Register,
    AppLayout
  },
  data() {
    return {};
  }
};
</script>