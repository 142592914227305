<template>
  <VBtn
    text:color="isConnected ? 'error' : 'primary'"
    :loading="isLoading"
    :small="small"
    @click="handleButtonClick"
  >
    <slot>{{ isConnected ? 'Disconnect' : 'Connect' }}</slot>
  </VBtn>
</template>
<script>
import { mapActions } from "vuex";
export default {
  template: ``,
  props: {
    account: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      realConfig: {},
      oauthToken: "",
      isDisabled: false,
      isLoading: true,
      defaultScopes: [
        "https://www.googleapis.com/auth/drive.file",
        "https://www.googleapis.com/auth/calendar",
        "https://www.googleapis.com/auth/plus.me",
        "https://www.googleapis.com/auth/userinfo.profile",
        "https://www.googleapis.com/auth/userinfo.email"
      ]
    };
  },
  computed: {
    isConnected() {
      return this.account instanceof Object && this.account.id >= 1;
    }
  },
  created() {
    this.realConfig = {
      client_id: this.config
        ? this.config.clientId || this.env.VUE_APP_GOOGLE_CLIENT_ID
        : this.env.VUE_APP_GOOGLE_CLIENT_ID,
      scope: this.config
        ? this.config.scope || this.defaultScopes.join(" ")
        : this.defaultScopes.join(" "),
      response_type: "id_token token code"
    };
    this.oauthToken = this.token;
    this.isDisabled = this.oauthToken && this.oauthToken.length > 0;

    this.loadUrl()
      .js("https://apis.google.com/js/api.js")
      .then(result => {
        this.isLoading = false;
        if (!window.gapi) {
          this.isDisabled = true;
          return console.warn("Google API not loaded");
        }
        window.gapi.load("auth2", () => {});
      });
  },
  methods: {
    ...mapActions({
      setAuthToken: "user/setAuthToken"
    }),
    handleAuthResult(authResult) {
      if (authResult && !authResult.error) {
        // const params = {...window.gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(), ...{
        //   offline_token: authResult.refresh_token,
        //   type: 'google'
        // }};
        const params = {
          token: authResult.token,
          code: authResult.code,
          type: "google"
        };
        this.oauthToken = params.access_token;
        this.setAuthToken(params)
          .then(result => {
            this.isDisabled = this.isLoading = false;
            this.$emit("connected", result);
          })
          .catch(error => {
            this.isDisabled = this.isLoading = false;
            this.$emit("error", error.message || error);
          });
        // Connect account credentials on the backend here
      } else {
        this.isDisabled = this.isLoading = false;
        return console.warn(authResult.details);
      }
    },
    async handleButtonClick() {
      this.isDisabled = this.isLoading = true;
      if (this.account && this.account.id) {
        this.$emit("remove:account", this.account);
        await this.waitFor(() => !this.account.id, 10000);
        this.isDisabled = this.isLoading = false;
      } else {
        // TODO: Need to figure out how to get auth instance setup when using authorize in order to get access token and store lonlived server access token
        // window.gapi.auth2.init(this.realConfig);
        window.gapi.auth2.authorize(this.realConfig, this.handleAuthResult);
      }
    }
  }
};
</script>