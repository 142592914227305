<template>
  <div class="alerts">
    <VSnackbar
      v-if="hasMessages"
      top
      multi-line
      color="info"
      class="pt-0"
      :elevation="6"
      :value="true"
      :timeout="timeout"
    >
      <span v-html="messages.join('<br/>')" />
      <template
        #action
        icon
        text
        color="white"
      >
        <VIcon @click="snackbar = false">
          highlight_off
        </VIcon>
      </template>
    </VSnackbar>
    <VSnackbar
      v-if="hasErrors"
      top
      multi-line
      class="pt-0"
      :timeout="0"
      :color="errorColor"
      :elevation="6"
      :value="true"
    >
      <span v-html="errors.join('<br/>')" />
      <template
        #action
        icon
        text
        color="white"
      >
        <VIcon @click="onClose">
          highlight_off
        </VIcon>
      </template>
    </VSnackbar>
  </div>
</template>

<script>
export default {
  name: "Notifications",
  props: {
    timeout: {
      type: Number,
      default: 5000,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    messages: {
      type: Array,
      default: () => [],
    },
    errorColor: {
      type: String,
      default: "warning",
    },
    messageColor: {
      type: String,
      default: "info",
    },
  },
  data() {
    return {
      snackbar: false,
      errorSnackbar: false,
    };
  },
  computed: {
    hasMessages() {
      return (
        this.messages instanceof Array &&
        this.messages.length &&
        this.snackbar === true
      );
    },
    hasErrors() {
      return (
        this.errors instanceof Array &&
        this.errors.length &&
        this.errorSnackbar === true
      );
    },
  },
  watch: {
    errors: function (value) {
      if (value instanceof Array && value.length > 0) {
        this.errorSnackbar = true;
      }
    },
    messages: function (value) {
      if (value instanceof Array && value.length > 0) {
        this.snackbar = true;
      }
    },
  },
  methods: {
    onClose() {
      this.errorSnackbar = this.snackbar = false;
    },
  },
  mounted() {
    this.snackbar = (this.messages || []).length > 0;
    this.errorSnackbar = (this.errors || []).length > 0;
    // setTimeout(() => {
    //   this.errors = this.messages = [];
    // }, this.timeout);
  },
};
</script>

