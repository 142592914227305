<template>
  <VCard class="settings-section">
    <VCardText>
      <VRow>
        <VCol
          cols="12"
          class="pb-0"
        >
          <p class="mb-2">
            Title
          </p>
          <TextField
            v-model="form.title"
            name="form.title"
            placeholder="Enter title..."
            class="textarea-field bg-title"
            :error="$v.form.title.$error"
            @input="$v.form.title.$touch()"
            @blur="$v.$touch()"
          />
        </VCol>
        <VCol
          cols="12"
          class="py-0"
        >
          <VList>
            <VListItem
              dense
              class="mb-6 lesson-settings"
            >
              <VListItemContent>Is this active?</VListItemContent>
              <VSpacer />
              <VListItemAction>
                <VSwitch
                  v-model="form.is_active"
                  inset
                  dense
                  :success="form.is_active"
                />
              </VListItemAction>
            </VListItem>
            <VListItem
              dense
              class="mb-6 lesson-settings"
            >
              <VListItemContent>Is this locked?</VListItemContent>
              <VSpacer />
              <VListItemAction>
                <VSwitch
                  v-model="form.is_locked"
                  inset
                  dense
                  :success="form.is_locked"
                />
              </VListItemAction>
            </VListItem>
            <VListItem
              dense
              class="mb-6 lesson-settings"
            >
              <VListItemContent>Is this gradable?</VListItemContent>
              <VSpacer />
              <VListItemAction>
                <VSwitch
                  v-model="form.is_gradable"
                  inset
                  dense
                  :success="form.is_gradable"
                />
              </VListItemAction>
            </VListItem>
            <!-- <VListItem
              dense
              class="mb-6 lesson-settings"
            >
              <VListItemContent>Does this have a due date?</VListItemContent>
              <VSpacer />
              <VListItemAction>
                <VSwitch
                  v-model="hasDueOnDate"
                  inset
                  dense
                  :success="hasDueOnDate"
                />
              </VListItemAction>
            </VListItem> -->
            <!-- <VListItem
              v-if="hasDueOnDate"
              dense
              class="mb-6 lesson-settings"
            >
              <VListItemContent>Due on a specific date?</VListItemContent>
              <VSpacer />
              <VListItemAction>
                <VSwitch
                  v-model="dueOnIsDate"
                  inset
                  dense
                  :success="dueOnIsDate"
                />
              </VListItemAction>
            </VListItem> -->
          </VList>
        </VCol>
      </VRow>
      <!-- <VRow v-if="hasDueOnDate">
        <VCol class="py-0">
          <p class="mb-2">
            Due On
          </p>
          <DateTimePicker
            v-model="form.due_on"
            label="Due On"
            class="due-dt-picker"
            :reactive="true"
            @input="updateDueOn"
          />
        </VCol>
        <VCol
          v-else
          class="py-0"
        >
          <VRow
            row
            wrap
          >
            <VCol class="pb-0">
              <TextField
                v-model="form.due_in"
                type="number"
                sm="4"
                label="Due In"
                placeholder="Enter due in value..."
                class="textarea-field"
                @keypress="numericOnly"
              />
            </VCol>
            <VCol class="pb-0">
              <Autocomplete
                v-model="form.due_in_unit"
                sm="4"
                label="Due In Unit"
                item-text="title"
                item-value="id"
                append-inner-icon="arrow-down"
                placeholder="Select Unit"
                class="textarea-field"
                :items="dueInUnits"
              />
            </VCol>
            <VCol class="pb-0">
              <TextField
                v-model="form.due_in_time"
                sm="4"
                label="(Optional) Time"
                type="time"
                append-inner-icon="arrow-down"
                placeholder="(Optional) Enter Time"
                class="textarea-field"
              />
            </VCol>
            <VCol
              cols="12"
              class="py-0"
            >
              <p>
                Enter a numeric value for the due in duration. for example select
                <b>1</b>and
                <b>Day</b> to make this due in one day.
              </p>
            </VCol>
          </VRow>
        </VCol>
      </VRow> -->
      <VRow>
        <VCol
          cols="12"
          class="pt-0"
        >
          <p class="mb-2">
            {{ featureName('Prerequisites') }}
          </p>
          <Autocomplete
            v-model="form.prerequisites"
            dense
            outlined
            multiple
            return-object
            class="textarea-field"
            :chips="false"
            :items="(assessmentType === 'Lesson' ? options.lessons : options.worksheets).filter(l => l.id != form.id)"
            :placeholder="`Set ${featureName('Prerequisites')}`"
          />
          <ReorderData
            v-if="form.prerequisites.length > 0"
            v-model="form.prerequisites"
            title="Prioritize Prerequisites"
            title-class="caption"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol class="d-flex justify-end">
          <GoogleDriveUI
            button-class="px-4 mr-2 dashed text-right"
            :rounded="true"
            :outlined="true"
            :can-connect-on-no-token="true"
            :files="form.files"
            @addFile="onAddFile"
            @removeFile="onRemoveFile"
          />
          <FileUpload
            text="Attach Files"
            button-class="px-4 dashed text-right"
            icon="cloud_upload"
            :as-button="true"
            :outlined="true"
            :rounded="true"
            :multiple="true"
            :files="form.files"
            @removeFile="onRemoveFile"
            @formData="onAddFile"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol
          cols="12"
          class="pb-0"
        >
          <DynamicInputs
            v-model="form.key_points"
            title="Key Points"
            choice-mode="user"
            custom-class="rounded"
            append-text="Add a Key Point"
            container-class="dynamic-input-container pb-5 textarea-field bg-title"
            :enable-sections="false"
            :enable-sections-value="false"
            :enable-sections-background="false"
            :show-delete-btn-text="false"
            :card="false"
            :input-name="featureName('Metadata')"
            :input-choices="[{
              text: 'Text',
              value: 'Text'
            }]"
            @new:input="value => onNewMetadata(value, null, 'key_points')"
            @remove:input="value => onRemoveMetadata(value, 'key_points')"
          >
            <template slot="title">
              <p class="mb-2">
                Key Points
              </p>
            </template>
          </DynamicInputs>
        </VCol>
        <VCol
          cols="12"
          class="pb-0"
        >
          <DynamicInputs
            v-model="form.do_now"
            title="Do Now"
            choice-mode="user"
            custom-class="rounded"
            append-text="Add a Due Now"
            container-class="dynamic-input-container pb-5 textarea-field bg-title"
            :enable-sections="false"
            :enable-sections-value="false"
            :enable-sections-background="false"
            :show-delete-btn-text="false"
            :card="false"
            :input-name="featureName('Metadata')"
            :input-choices="[{
              text: 'Text',
              value: 'Text'
            }]"
            @new:input="value => onNewMetadata(value, null, 'do_now')"
            @remove:input="value => onRemoveMetadata(value, 'do_now')"
          >
            <template slot="title">
              <p class="mb-2">
                Due Now
              </p>
            </template>
          </DynamicInputs>
        </VCol>
      </VRow>
    </VCardText>
    <VCardActions v-if="mode === 'standalone'">
      <VBtn
        text
        @click.native="$emit('toggle:form:dialog')"
      >
        Cancel
      </VBtn>
      <VSpacer />
      <VBtn
        class="wethrive"
        @click="$emit('input', form)"
      >
        Save
      </VBtn>
    </VCardActions>
  </VCard>
</template>
<script>
import MetadataMixin from "@/mixins/Metadata";
import FormMixin from "@/mixins/Form";
import HasDueMixin from "@/mixins/HasDueOn";
import { required, minLength, between } from "vuelidate/lib/validators";
import DynamicInputs from "@/components/Elements/Forms/DynamicInputs";
import GoogleDriveUI from "@/components/Elements/Forms/GoogleDriveUI";
import ReorderData from "@/components/Elements/Data/ReorderData";
export default {
  name: "LessonSettings",
  props: {
    existing: {
      type: Object,
      default: () => {},
    },
    assessmentType: {
      type: String,
      default: "Lesson",
    },
    customOptions: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "dialog",
    },
  },
  components: { DynamicInputs, GoogleDriveUI, ReorderData },
  mixins: [FormMixin, MetadataMixin, HasDueMixin],
  data() {
    return {
      defaultForm: {
        is_locked: false,
        is_active: true,
        visible_to: [],
        prerequisites: [],
      },
    };
  },
  created() {
    this.form = this.existing;
    this.options = this.customOptions;
    this.setDueOn(this.form);
  },
  data() {
    return {};
  },
  // watch: {
  //   form: {
  //     deep: true,
  //     handler() {
  //       this.$emit("input:update", this.form);
  //     },
  //   },
  // },
  methods: {
    updateDueOn(datetime) {
      this.form.due_on = datetime;
    },
  },
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(4),
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-settings {
  border: solid 0.5px #eaeaea;
  border-radius: 5px;
  min-height: 40px !important;
  height: 40px;
}
.due-dt-picker fieldset {
  border-radius: 5px !important;
}
</style>

<style lang="scss">
.due-dt-picker fieldset {
  border-radius: 5px !important;
}
.bg-title fieldset {
  background-color: #e6e6e6 !important;
}
.settings-section .dynamic-title {
  padding-left: 20px !important;
  .v-input__append-outer {
    margin-top: 4px !important;
    margin-bottom: 0px !important;
  }
}
</style>