<template>
  <section class="register-form align-self-center layout column justify-center">
    <VCard>
      <VCardTitle id="form-header">
        <h3>Register</h3>
      </VCardTitle>
      <VCardText>
        <template v-if="!activity.verifyingInvite">
          <VAlert
            v-if="error"
            dismissible
            :value="true"
          >
            {{ error }}
          </VAlert>
          <VTabs
            v-model="active"
            slider-color="#F5BA2B"
            grow
          >
            <VTab
              v-if="isEnabledFeature('registration')"
              ripple
            >
              Create Team
            </VTab>
            <VTab ripple>
              Join Existing Team
            </VTab>
            <VTabsItems
              touchless
              v-model="active"
            >
              <VTabItem v-if="isEnabledFeature('registration')">
                <RegisterOrganizationForm />
              </VTabItem>
              <VTabItem>
                <RegisterMemberForm :invite="invite" />
              </VTabItem>
            </VTabsItems>
          </VTabs>
        </template>
        <template v-else>
          <h2>Verifying Invitation</h2>
          <VProgressLinear
            indeterminate
            color="primary"
            class="mb-0"
          />
        </template>
      </VCardText>
    </VCard>
    <VCard class="mt-2">
      <VCardText
        id="register-form-footer"
        class="d-flex align-center"
      >
        <VRow align-center>
          <VCol id="register">
            <VRow
              align-center
              justify-start
            >
              <router-link
                to="/"
                class="px-3"
              >
                Already Registered? Log In.
              </router-link>
            </VRow>
          </VCol>
          <VSpacer />
          <VCol id="badge">
            <VRow
              align-center
              justify-end
              class="px-3"
            >
              <span>Powered by&nbsp;</span>
              <span>
                <img
                  style="height: 26px;"
                  :src="WeThriveLogo"
                >
              </span>
            </VRow>
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
  </section>
</template>

<script>
import WeThriveLogo from "@/images/logo_wethrivehub.png";
import RegisterMemberForm from "./MemberForm";
import RegisterOrganizationForm from "./OrganizationForm";
import { AUTH_REQUEST, REGISTER_REQUEST } from "@/store/actionTypes";
import { required, minLength, between } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
import NeedsTeamsMixin from "@/mixins/NeedsTeams";
import { mapActions } from "vuex";

export default {
  name: "RegisterForm",
  components: {
    RegisterMemberForm,
    RegisterOrganizationForm,
  },
  mixins: [NeedsTeamsMixin, FormMixin],
  props: {},
  data() {
    return {
      WeThriveLogo,
      active: 0,
      invite: null,
      error: null,
      activity: {
        verifyingInvite: false,
      },
    };
  },
  methods: {
    ...mapActions({
      getInvitation: "team/verifyInvite",
    }),
  },
  created() {
    if (this.$route.params.team) {
      this.active = this.isEnabledFeature("registration") ? 1 : 0;
    }
    if (this.$route.query.invitation) {
      this.activity.verifyingInvite = true;
      this.getInvitation(this.$route.query.invitation)
        .then((invite) => {
          if (invite instanceof Object && invite.id) {
            this.invite = invite;
            this.active = this.isEnabledFeature("registration") ? 1 : 0;
          } else if (invite == "No records") {
            this.error =
              "Your invitation is not valid. You may contact the team owner to ask for another invite";
          }
          this.activity.verifyingInvite = false;
        })
        .catch((error) => {
          this.error =
            "Your invitation is expired. You may contact the team owner to ask for another invite";
          this.$log.warn("Invite not found");
          this.activity.verifyingInvite = false;
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.register-form {
  #register-form-header h3 {
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    margin-bottom: 40px;
    margin-left: 40px;
  }

  #circle {
    display: inline-block;
    background-color: #9bf2dc;
    border-radius: 50%;
    line-height: 5rem;
    height: 5rem;
    width: 5rem;
    text-align: center;
  }

  .form {
    background-color: $white-color;
    padding: 1rem;

    #form-header {
      border-bottom: 1px solid $border-color;
      margin: 0 -1rem 1rem -1rem;

      h3 {
        padding: 0 1rem;
      }
    }

    label {
      font-size: 0.8rem;
    }

    span input {
      width: 100%;
      height: 48px;
      font-size: 14px;
      border: none;
      border-bottom: 1px solid $border-color;
      box-shadow: none;
      box-sizing: border-box;
      margin-bottom: 1rem;

      &:focus {
        outline: none;
      }
    }

    .register-select {
      min-width: 100%;

      &:after {
        content: "\f078";
        font: normal normal normal 17px/1 FontAwesome;
        color: $border-color;
        right: 11px;
        top: 6px;
        height: 34px;
        position: absolute;
        pointer-events: none;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        // appearance: none; // leave this here for when browsers start supporting the non-prefixed property
        display: block;
        width: 100%;
        height: 50px;
        margin: 5px 0px;
        font-size: 16px;
        line-height: 1.75;
        color: #333;
        background-color: $white-color;
        background-image: none;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: 1px solid $border-color;
        -ms-word-break: normal;
        word-break: normal;

        &::-ms-expand {
          display: none;
        }
      }
    }

    button {
      border: 1px;
      box-shadow: none;
      width: 70%;
      margin-left: 15%;
      margin-bottom: 1rem;
      margin-top: 1rem;
      height: 50px;
      color: white;
    }

    #amnesia {
      text-align: right;
      font-size: 0.8rem;
    }
  }

  #register-form-footer {
    height: 72px;
    font-size: 0.8rem;

    #register a {
      height: 12px;
      color: $font-light-color;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
    }

    #badge {
      text-align: right;

      span {
        display: inline-block;
      }
    }
  }
}
</style>