<template>
  <div>
    <VNavigationDrawer
      v-if="selectTeam"
      floating
      id="select-team"
      height="min-content"
      elevation="0"
      class="container pt-0"
      :width="$vuetify.breakpoint.mdAndUp ? '669px' : '100%'"
      :value="true"
      :disable-resize-watcher="false"
      :temporary="true"
      :fixed="true"
      :style="{
        left: $vuetify.breakpoint.smAndDown ? '0' : ''
      }"
    >
      <VRow
        style="height: 56px"
        @click.stop="$emit('close')"
      >
        <VCol
          :style="{
            paddingLeft: $vuetify.breakpoint.mdAndUp ? '0px' : '66px'
          }"
        >
          <TeamMenuActivator
            is-open
            @input="$emit('close')"
          />
        </VCol>
      </VRow>
      <VRow :column="$vuetify.breakpoint.smAndDown">
        <VCol
          xs="12"
          md="5"
          :order="$vuetify.breakpoint.smAndDown ? 2 : 1"
          :class="{
            'pl-0': $vuetify.breakpoint.mdAndUp
          }"
        >
          <VList
            v-if="$user.teams && $user.teams.length"
            two-line
            dense
            elevation="6"
            color="white"
            :style="{
              maxHeight: 'calc(100vh - 76px)',
              overflowY: 'auto'
            }"
          >
            <VListItem
              v-for="(team, key) in $user.teams"
              :key="key"
              :class="{
                'primary--text': $team.id === team.id
              }"
              @click.stop="onSelectTeam(team.id)"
            >
              <VListItemContent>
                <span>
                  <img :src="team.photo_url">
                </span>
                <!-- <VListItemTitle><small :class="{'info--text': team.pivot && team.pivot.role === 'owner'}">{{ getRoleName(team) }}</small></VListItemTitle> -->
                <VListItemSubtitle style="font-size: 0.65rem; text-transform: uppercase; font-weight: bold">
                  {{ team.name }}
                </VListItemSubtitle>
              </VListItemContent>
              <VListItemIcon
                class="align-center"
                style="margin: 20px 0 0"
              >
                <VTooltip
                  top
                  center
                  :value="!team.pivot || team.pivot && !team.pivot.is_approved"
                >
                  <template #activator="{on}">
                    <VIcon
                      small
                      :color="$team.id === team.id ? 'primary' : 'transparent'"
                      v-on="on"
                    >
                      <template v-if="!team.pivot || team.pivot && !team.pivot.is_approved">
                        help
                      </template>
                      <template v-else>
                        fiber_manual_record
                      </template>
                    </VIcon>
                  </template>
                  <template v-if="!team.pivot || team.pivot && !team.pivot.is_approved">
                    You're not approved yet
                  </template>
                </VTooltip>
              </VListItemIcon>
            </VListItem>
          </VList>

          <VBtn
            v-else
            block
            class="wethrive"
            color="primary"
            @click="dialogs.signup = true"
          >
            Join a team
          </VBtn>
        </VCol>
        <VCol
          xs="12"
          md="7"
          :order="$vuetify.breakpoint.smAndDown ? 1 : 2"
        >
          <VBtn
            v-if="$user.teams && $user.teams.length"
            block
            class="wethrive"
            color="primary"
            @click.prevent="() => {$emit('close'); dialogs.signup = true}"
          >
            Join a team
          </VBtn>
          <PendingInvitations
            class="mt-2"
            style="max-height: calc(100vh - 128px);overflow-y: auto;"
            @accepted="onHandleAcceptInvitation"
            @rejected="onHandleRejectInvitation"
          />
        </VCol>
      </VRow>
    </VNavigationDrawer>
    <VDialog
      v-model="dialogs.signup"
      scrollable
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="false"
      :persistent="true"
      :width="640"
    >
      <VCard>
        <VCardTitle>
          <VRow>
            Register for a team
            <VSpacer />
            <VBtn
              icon
              text
              @click.prevent="dialogs.signup = false"
            >
              <VIcon color="error">
                close
              </VIcon>
            </VBtn>
          </VRow>
        </VCardTitle>
        <VCardText>
          <VAlert
            v-if="!isSuccessFullOpenRegistration && !needsConfirmation && !$hasTeams"
            type="info"
            :value="true"
          >
            You do not belong to any teams. Please sign up for a team below. To begin, search
            for a team below.
          </VAlert>
          <RegisterExistingMemberForm
            :user-form="$user"
            @close="dialogs.signup = false"
            @needsConfirmation="onNeedsConfirmation"
            @isSuccessFullOpenRegistration="onIsSuccessFullOpenRegistration"
          />
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import PendingInvitations from "../Users/PendingInvitations";
import { mapActions } from "vuex";
import RegisterExistingMemberForm from "@/components/Register/ExistingMemberForm";
import TeamMenuActivator from "@/components/Elements/Notifications/TeamMenuActivator";

export default {
  name: "SelectTeam",
  components: {
    RegisterExistingMemberForm,
    PendingInvitations,
    TeamMenuActivator,
  },
  props: {
    selectTeam: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogs: {
        signup: false,
      },
      isSelectingTeam: false,
      isSuccessFullOpenRegistration: false,
      needsConfirmation: false,
      hasNoTeams: false,
    };
  },
  watch: {
    $hasUser: function (value) {
      if (value && !this.$hasTeams) {
        this.dialogs.signup = true;
      }
    },
  },
  created() {
    if (this.$hasUser && !this.$hasTeams) {
      this.dialogs.signup = true;
      this.hasNoTeams = true;
    }
  },
  methods: {
    ...mapActions({
      doSetCurrentTeam: "user/setCurrentTeam",
      doLogout: "auth/logout",
      getMe: "user/me",
    }),
    isTeamDisabled(teamId) {
      const teamUser = this.$team.pivot || this.$user.team_user || {};
      return (
        this.$team.id === teamId ||
        this.isSelectingTeam ||
        !teamUser instanceof Object ||
        (teamUser instanceof Object && !teamUser.is_approved)
      );
    },
    onSelectTeam(teamId) {
      this.$log.debug("Switching", teamId);
      this.$log.debug("Got this far");
      this.isSelectingTeam = true;
      const currentTeamSlug = this.$team.slug;
      this.$log.debug("Got this far", currentTeamSlug);
      this.doSetCurrentTeam(teamId).then((team) => {
        const newUrl = window.location.href.replace(
          "/" + currentTeamSlug,
          "/" + team.slug
        );
        // Sometimes we don't have a team slug so just reload
        if (newUrl === window.location.href) {
          window.location.reload();
        } else {
          window.location.href = newUrl;
        }
      });
    },
    onNeedsConfirmation() {
      this.needsConfirmation = true;
    },
    onIsSuccessFullOpenRegistration() {
      this.isSuccessFullOpenRegistration = true;
    },
    getRoleName(team) {
      let role =
        team.pivot && team.pivot.role_name ? team.pivot.role_name : null;
      if (!role && team.roles instanceof Array) {
        const teamRole = team.roles.find(
          (value, index) => value.id == (team.pivot ? team.pivot.role : -1)
        );
        role = teamRole instanceof Object ? teamRole.name : "-";
      }
      return role;
    },
    onHandleRejectInvitation(invitation) {
      this.$log.debug("Rejected", invitation);
    },
    onHandleAcceptInvitation(invitation) {
      if (!this.needsConfirmation && this.hasNoTeams) {
        window.location.reload();
      }
    },
  },
};
</script>

<style lang="scss">
#select-team {
  .v-navigation-drawer__content {
    padding-left: 12px;
    padding-right: 12px;
    overflow-y: visible;
    overflow-x: visible;
    height: max-content !important;
  }
}
</style>