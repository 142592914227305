<template>
  <AppLayout fluid>
    <VContainer
      id="login-page"
      fill-height
      fluid
    >
      <VRow align-center>
        <VCol
          cols="12"
          offset-md="2"
          md="5"
          lg="4"
          d-flex
        >
          <LoginForm />
        </VCol>
      </VRow>
    </VContainer>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AlternateAppLayout";
import LoginForm from "@/components/Login";

export default {
  name: "Login",
  components: {
    LoginForm,
    AppLayout
  },
  data() {
    return {};
  }
};
</script>