import api from '../../services/register';

export default function (helper) {
  helper.setApi(api);

  const TYPES = {
    REGISTER_ERROR: 'REGISTER_ERROR',
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_GET_INVITATION: 'REGISTER_GET_INVITATION'
  };

  const state = {
    error: "",
    status: "",
    invitation: null
  };

  const getters = {
    error: state => state.error,
    status: state => state.status,
    invitation: state => state.invitation
  };

  const actions = {
    getInvitations(context, params) {
      helper
        .log()
        .info(`[Store: Register}]: Get Invitations`, params);
      return new Promise((resolve, reject) => {
        return helper
          .api()
          .getInvitations(params)
          .then((response) => {
            helper
              .log()
              .info(`[Store: Register]: Got invitation}`, response.data);
            context.commit(TYPES.REGISTER_GET_INVITATION, {params, result: response.data});
            resolve(context.getters.invitation);
          })
          .catch((error) => {
            helper
              .log()
              .info(`[Store: Register]: Error getting invitation`, error);
            reject(error);
          })
      });
    },
    register(context, user) {
      helper
        .log()
        .info(`[Store: Register}]: Register`, user);
      return new Promise((resolve, reject) => {
        context.commit(TYPES.REGISTER_REQUEST);
        helper
          .api()
          .register(user.data)
          .then(resp => {
            context.commit(TYPES.REGISTER_SUCCESS);
            resolve(resp.data);
          })
          .catch(err => {
            context.commit(TYPES.REGISTER_ERROR, err);
            state.error = "Oops. Something went wrong. Please try again.";
            reject(err);
          });
      });
    }
  };

  const mutations = {
    [TYPES.REGISTER_REQUEST]: state => {
      state.status = "loading";
    },
    [TYPES.REGISTER_SUCCESS]: state => {
      state.error;
      state.hasLoadedOnce = true;
    },
    [TYPES.REGISTER_ERROR]: (state, error) => {
      state.status = "error";
      state.error = error;
    },
    [TYPES.REGISTER_GET_INVITATION]: (state, invitation) => {
      state.invitation = invitation;
    }
  };

  return {namespaced: true, state, getters, actions, mutations};
}
