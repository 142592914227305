<template>
  <VRow
    column
    class="login-form"
  >
    <VCard class="content-bg">
      <VCardTitle id="form-header">
        <h3>Sign in</h3>
      </VCardTitle>
      <VCardText>
        <VAlert
          v-if="error && error.length"
          :value="true"
          type="warning"
        >
          {{ error }}
        </VAlert>
        <SmallTeamBanner
          v-if="currentTeam"
          :team="currentTeam"
        />
        <VAlert
          v-if="$route.params.team && !currentTeam && !isSearchingTeam"
          :value="true"
          type="info"
        >
          Couldn't find team:
          <strong>{{ ($route.params.team || "").titleize() }}</strong>. You'll be logged into your most recent team.
        </VAlert>
        <form
          class="form"
          name="login"
          @submit.prevent="login"
        >
          <TextField
            outlined
            v-model="form.email"
            label="Email"
            name="email"
            type="text"
            :error="$v.form.email.$error"
            @keyup.enter="login"
          />
          <PasswordInput
            v-model="form.password"
            :disabled="false"
            :editable="false"
            :error="$v.form.password.$error"
            @keyup.enter="login"
          />
          <VCheckbox
            v-model="rememberMe"
            label="Remember Me"
            type="checkbox"
          />
          <VBtn
            :class="submitClass"
            :disabled="!isFormReady"
            @click.prevent="login"
          >
            {{ buttonText }}
          </VBtn>
          <div id="amnesia">
            <router-link to="/recover">
              Forgot Username or Password?
            </router-link>
          </div>
        </form>
      </VCardText>
    </VCard>
    <VCol>
      <VRow
        id="login-form-footer"
        class="mt-2"
      >
        <VCol id="login">
          <VRow
            fill-height
            align-center
            justify-start
          >
            <router-link
              v-if="isEnabledFeature('registration')"
              to="/register"
              class="text-no-wrap"
            >
              Not Registered? Register Here!
            </router-link>
          </VRow>
        </VCol>
        <VSpacer />
        <VCol id="badge">
          <VRow
            align-center
            justify-end
          >
            <span>Powered by&nbsp;</span>
            <span>
              <img
                style="height: 26px;"
                :src="WeThriveLogo"
              >
            </span>
          </VRow>
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import WeThriveLogo from "@/images/logo_wethrivehub.png";
import { AUTH_REQUEST, AUTH_REMEMBER } from "@/store/actionTypes";
import { email, required, minLength, between } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
import NeedsTeamsMixin from "@/mixins/NeedsTeams";
import { mapActions } from "vuex";
import PasswordInput from "@/components/Elements/Forms/PasswordInput";

export default {
  name: "LoginForm",
  mixins: [NeedsTeamsMixin, FormMixin],
  components: { PasswordInput },
  props: {},
  data() {
    return {
      WeThriveLogo,
      isLoading: false,
      error: false,
      submitClass: "wethrive",
      buttonText: "Login",
      form: {
        password: "",
        email: "",
        teamSlug: "",
      },
    };
  },
  validations: {
    teamSlug: {
      minLength: minLength(3),
    },
    form: {
      password: {
        required,
      },
      email: {
        email,
        required,
      },
    },
  },
  computed: {
    username: {
      get() {
        if (localStorage.email) {
          return localStorage.email;
        } else {
          return this.form.email;
        }
      },
      set(value) {
        this.form.email = value;
      },
    },
    rememberMe: {
      get() {
        return this.$store.state.auth.rememberMe;
      },
      set(value) {
        this.doRememberMe(
          {
            value,
            email: this.form.email,
          },
          { root: true }
        );
      },
    },
  },
  created() {
    this.doLogout();
  },
  methods: {
    ...mapActions({
      doLogin: "auth/login",
      doLogout: "auth/logout",
      doRememberMe: "auth/rememberMe",
    }),
    login() {
      if(!this.isFormReady) {
        return false;
      }
      const { username, password = this.form.password } = this;
      this.isLoading = true;
      this.error = "";
      this.buttonText = "Logging you in...";
      this.doLogin({
        username: username.toLowerCase().trim(),
        password,
        rememberMe: this.rememberMe,
      })
        .then(() => {
          this.isLoading = false;
          this.submitClass = "wethrive-alt smaller--text";
          this.buttonText = "Getting profile...";
          this.$log.debug("LoginForm.login: Logged in...waiting for user");

          /**
           * Get the user and log them in
           * TODO: Need to add custom logic here to determine if the user is properly setup
           * Possibly need a flag on the user account or a check on the org info
           */
          // Need to set the current team for the user using API
          // this.$team = this.currentTeam ? this.currentTeam : this.$team;
          // const team = this.$team ? this.$team.slug : this.teamSlug;
          const ready = function() {
            this.$log.debug("LoginForm.login: User is available", this.$team);
            // Will return to using this if problems return with being logged into previous team
            // const previousUrl = (
            //   this.$auth.redirect() || {
            //     from: { fullPath: null },
            //     to: { fullPath: null }
            //   }
            // ).from.fullPath;
            // window.location.href =
            //   previousUrl ||
            //   `${this.teamSlug ? "/" + this.teamSlug : ""}/app/dashboard`;
            const redirect = this.$auth.redirect();
            this.$router.push(
              redirect instanceof Object
                ? redirect.from
                : {
                    name: "dashboard",
                    params: {
                      team: this.$team.slug,
                    },
                  }
            );
          };

          setTimeout(() => {
            if (!this.$auth.ready()) {
              this.$auth.ready(ready);
            } else {
              ready.call(this);
            }
          }, 500);
        })
        .catch((error) => {
          console.log("Error", error);
          if (error.code == 403) {
            window.location.reload();
          } else {
            const response = error ? error.response : null;
            this.$log.debug("Error information", response, error);
            this.error = response
              ? error.response.data.message
              : "Error logging you in";
            this.isLoading = false;
            this.buttonText = "Login";
            this.doLogout();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.login-form {
  #login-form-header h3 {
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    margin-bottom: 40px;
    margin-left: 40px;
  }

  #circle {
    display: inline-block;
    background-color: #9bf2dc;
    border-radius: 50%;
    line-height: 5rem;
    height: 5rem;
    width: 5rem;
    text-align: center;
  }

  .form {
    background-color: $white-color;
    padding: 1rem;

    #form-header {
      border-bottom: 1px solid $border-color;
      margin: 0 -1rem 1rem -1rem;

      h3 {
        color: $content-title-color;
        font-size: 13px;
        font-weight: 900;
        letter-spacing: 1px;
        line-height: 14px;
        text-transform: uppercase;
      }
    }

    label {
      font-size: 0.8rem;
    }

    span input {
      width: 100%;
      height: 48px;
      font-size: 14px;
      border: none;
      border-bottom: 1px solid $border-color;
      box-shadow: none;
      box-sizing: border-box;
      margin-bottom: 1rem;

      &:focus {
        outline: none;
      }
    }

    button {
      border: 1px;
      box-shadow: none;
      width: 70%;
      margin-left: 15%;
      margin-bottom: 1rem;
      margin-top: 1rem;
      height: 50px;
      color: white;
    }

    #amnesia {
      text-align: right;
      font-size: 0.8rem;

      a {
        color: $font-light-color;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
      }
    }
  }

  #login-form-footer {
    margin-top: 20px;
    font-size: 0.8rem;

    #login a {
      color: $font-light-color;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
    }

    #login {
      grid-area: login;
    }

    #badge {
      grid-area: badge;
      text-align: right;

      span {
        display: inline-block;
      }
    }
  }
}
</style>
