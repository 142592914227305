<template>
  <section class="register-organization-form">
    <Alerts
      v-if="hasMessagesOrErrors"
      :messages="messages"
      :errors="errors"
    />
    <VAlert
      v-if="currentTeam"
      :value="true"
      type="warning"
    >
      This team already exists. You may request access to the team by using the
      <strong>Join Existing Team</strong> form above.
    </VAlert>
    <TextField
      v-model="form.team.name"
      label="Team Name"
      name="teamSlug"
      type="text"
      :disabled="isSearchingTeam"
      @keyup.enter.prevent="checkTeam"
    >
      <VBtn
        slot="append"
        icon
        :loading="isSearchingTeam"
        @click.prevent="findTeam"
      >
        <VIcon>search</VIcon>
      </VBtn>
    </TextField>
    <VBtn
      v-if="!canRegisterTeam"
      block
      class="wethrive-alt"
      @click="checkTeam"
    >
      Check Team
    </VBtn>
    <form
      class="form"
      name="login"
      @submit.prevent="() => false"
    >
      <template v-if="canRegisterTeam">
        <TextField
          v-model="form.team.user_role_name"
          label="What's the name of your user?"
          type="text"
          :disabled="isLoading || teamExists || !form.team.name"
        />
        <TextField
          v-model="form.team.slug"
          name="slug"
          label="Team Url"
          :placeholder="form.website"
          type="text"
          class="url-input"
          :disabled="isLoading || teamExists || !form.team.name"
          append-icon="file_copy"
          @click:append="
            () => copyTextToClipboard(`https://app.wethrive.tech/${form.slug}`)
          "
        >
          <span slot="prepend-inner">app.wethrive.tech/</span>
        </TextField>
        <TextField
          v-model="form.team.website"
          label="Website"
          type="text"
          :disabled="isLoading || teamExists || !form.team.name"
        />
        <TextField
          v-model="form.name"
          label="Name"
          type="text"
          :disabled="isLoading || teamExists || !form.team.name"
        />
        <TextField
          v-model="form.email"
          label="Email"
          type="email"
          :disabled="isLoading || teamExists || !form.team.name"
          :error="$v.form.email.$error"
          @blur="$v.$touch()"
        />
        <PasswordInput
          v-model="form.password"
          :editable="false"
          :disabled="isLoading || teamExists || !form.team.name"
          :error="$v.form.password.$error"
          @input="$v.$touch()"
        />
        <PasswordInput
          v-if="form.password"
          v-model="form.password_confirmation"
          label="Confirm Password"
          :editable="false"
          :disabled="isLoading || teamExists || !form.team.name"
          :error="$v.form.password_confirmation.$error"
          @input="$v.$touch()"
        />
        <VCheckbox
          v-model="form.agreement"
          type="checkbox"
          label="Check here to agree to our"
        />
        <VBtn
          text
          small
          color="secondary"
          @click="dialogs.terms = true"
        >
          Terms and Conditions
        </VBtn>&nbsp; and &nbsp;
        <VBtn
          text
          small
          color="secondary"
          @click="dialogs.privacy = true"
        >
          Privacy Policy
        </VBtn>
        <VBtn
          class="mt-2"
          color="success"
          :class="submitClass"
          @click.prevent="register"
        >
          {{ submitText }}
        </VBtn>
      </template>
    </form>
    <Dialog
      :is-dialog-open="dialogs.privacy"
      title="Privacy Policy"
      @toggle:dialog="() => (dialogs.privacy = false)"
    >
      <Privacy mode="dialog" />
    </Dialog>
    <Dialog
      :is-dialog-open="dialogs.terms"
      title="Terms and Conditions"
      @toggle:dialog="() => (dialogs.terms = false)"
    >
      <Terms mode="dialog" />
    </Dialog>
  </section>
</template>

<script>
import { AUTH_REQUEST, REGISTER_REQUEST } from "@/store/actionTypes";
import { email, required, sameAs } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
import NeedsTeamsMixin from "@/mixins/NeedsTeams";
import { mapActions } from "vuex";
import Privacy from "@/components/App/Privacy";
import Terms from "@/components/App/Terms";
import PasswordInput from "@/components/Elements/Forms/PasswordInput";

export default {
  name: "RegisterOrgForm",
  mixins: [NeedsTeamsMixin, FormMixin],
  components: { Privacy, Terms, PasswordInput },
  props: {},
  data() {
    return {
      error: false,
      submitClass: "wethrive",
      submitText: "Create",
      dialogs: {
        privacy: false,
        terms: false,
      },
      form: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role: "owner",
        team: {
          name: "",
          slug: "",
          website: "",
          user_role_name: "Mentor",
        },
        terms: true,
        agreement: false,
      },
      isLoading: false,
      canRegisterTeam: false,
      modelType: "Registration",
      teamSearchMode: "creating",
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      password: {
        required,
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs("password"),
      },
      email: {
        email,
        required,
      },
      team: {
        name: {
          required,
        },
      },
      agreement: {
        sameAs: sameAs(() => true),
      },
    },
  },
  methods: {
    ...mapActions({
      doRegister: "register/register",
      doLogin: "auth/login",
    }),
    register() {
      const password = this.form.password;
      return this.saveFormModel(this.doRegister)
        .then((resp) => {
          if (resp.status != "error") {
            this.isLoading = false;
            this.submitClass = "wethrive-alt";
            this.submitText = "Creating ...";
            const { username = this.form.email } = this.form;
            this.doLogin({
              username,
              password,
            }).then(() => {
              this.submitText = "Created ... one sec";
              this.$log.debug(
                "RegisterOrganizationForm.register: Registered ... waiting for user"
              );
              this.$bus.$emit(
                "notificationMessage",
                "Registration was successful!"
              );

              const ready = function() {
                // window.location.href = `${team ? '/'+team : ''}/app/dashboard`;
                this.$log.debug(
                  "RegisterOrganizationForm.login: User is available"
                );
                this.$router.push({
                  name: "dashboard",
                  params: {
                    team: this.$team.slug,
                  },
                });
              };

              setTimeout(() => {
                if (!this.$auth.ready()) {
                  this.$auth.ready(ready);
                } else {
                  ready.call(this);
                }
              }, 500);
            });
          } else {
            this.errors = resp.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    checkTeam() {
      const name = this.form.team.name;
      this.$log.debug("Name", name);
      if (name && name.length >= 3) {
        this.form.team.slug = name.slugify();
        this.teamSlug = this.form.team.slug;
        this.findTeam().then((result) => {
          this.$log.debug(result);
          this.canRegisterTeam = result == null;
        });
      }
    },
  },
};
</script>
<style>
.url-input .v-input__prepend-inner {
  align-self: center !important;
}
</style>
