<template>
  <AppLayout>
    <VContainer
      id="register-page"
      fill-height
      fluid
    >
      <Dialog
        fullscreen
        :dismissable="false"
        :is-dialog-open="true"
        fill-height
        :card-class="{
          'dark-overlay': true
        }"
      >
        <VRow
          fill-height
          row
          align-center
          justify-center
        >
          <VCol
            lg="6"
            md="8"
            cols="12"
          >
            <RequestInvitation />
          </VCol>
        </VRow>
      </Dialog>
    </VContainer>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AlternateAppLayout";
import RequestInvitation from "@/components/Register/RequestInvitation";

export default {
  name: "RequestInvitationToTeam",
  components: {
    RequestInvitation,
    AppLayout
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
/** extra imports **/
@import "../styles/variables.scss";
</style>