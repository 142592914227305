<template>
  <VApp
    class="wethrive"
    :style="style"
  >
    <Notifications
      v-if="hasMessagesOrErrors"
      :messages="messages"
      :errors="errors"
      :timeout="notificationTimeout"
    />
    <VRow
      row
      wrap
      justify-flex-end
      fill-height
      :px-3="$vuetify.breakpoint.mdAndUp && !fluid"
      :px-1="$vuetify.breakpoint.smAndDown && !fluid"
      :py-2="!fluid"
    >
      <VCol
        :xs="$vuetify.breakpoint.smAndDown || fluid ? 12 : false"
        class="d-flex align-center py-0"
      >
        <slot />
      </VCol>
      <VCol
        v-if="enableFooter"
        :cols="12"
        :fill-height="$vuetify.breakpoint.mdAndUp && !fluid"
        :class="{
          'text-sm-center text-md-right': true,
          'd-flex py-0 px-0': true,
          'align-end': true,
          'justify-end': true,
          'footer-section': true
        }"
      >
        <div
          :class="{
            'px-2 pb-2 pt-0': true,
            'align-center': $vuetify.breakpoint.smAndDown,
            'd-flex': $vuetify.breakpoint.smAndDown,
          }"
          :style="{
            position: $vuetify.breakpoint.mdAndUp && !fluid ? 'fixed' : 'auto',
            right: $vuetify.breakpoint.mdAndUp ? '0' : 'auto',
          }"
        >
          <VBtn
            text
            small
            href="https://wethrive.tech/"
            target="_blank'"
          >
            About WeThrive
          </VBtn>&nbsp; | &nbsp;
          <VBtn
            text
            small
            :to="{name: 'terms-and-conditions'}"
          >
            Terms and Conditions
          </VBtn>&nbsp; | &nbsp;
          <VBtn
            text
            small
            :to="{name: 'privacy-policy'}"
          >
            Privacy Policy
          </VBtn>
        </div>
      </VCol>
    </VRow>
  </VApp>
</template>

<script>
import NotificationMixin from "@/mixins/Notification";
import BackgroundImage from "@/images/illustration.jpg";

export default {
  name: "AlternateAppLayout",
  mixins: [NotificationMixin],
  props: {
    enableBackground: {
      type: Boolean,
      default: true,
    },
    fluid: {
      type: Boolean,
      default: true,
    },
    enableFooter: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    style() {
      return {
        backgroundImage: this.enableBackground
          ? `url('${BackgroundImage}')`
          : "",
        backgroundPosition: "bottom right",
        backgroundSize: "auto 100%",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        paddingBottom: "0px",
        overflow: "hidden auto",
        maxHeight: "100vh",
      };
    },
  },
  methods: {
    initIntercom() {
      this.$log.debug("[App]: Intercom: booting");
      this.$intercom.boot({
        userId: this.$user.id,
        user_id: this.$user.id,
        name: this.$user.name,
        email: this.$user.email,
        role: this.$user.role_name,
        user_hash: this.$user.hash,
        company_id: this.$user.current_team_id,
        company_website: this.$team.website,
      });
      this.$intercom.hide();
      this.$log.debug("[App]: Intercom: booted");
    },

    onToggleIntercom() {
      if (!this.$intercom.visible) {
        this.$intercom.show();
      } else {
        this.$intercom.hide();
      }
    },
  },
  created() {
    this.$bus.$on("toggleIntercom", () => {
      this.onToggleIntercom();
    });
  },
};
</script>