<template>
  <AppLayout>
    <VContainer
      id="login-page"
      fill-height
      fluid
    >
      <VRow align-center>
        <VCol
          md="5"
          offset-md="1"
          offset-lg="2"
          lg="4"
          cols="12"
        >
          <ResetForm />
        </VCol>
      </VRow>
    </VContainer>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AlternateAppLayout";
import ResetForm from "@/components/Login/Reset";

export default {
  name: "Reset",
  components: {
    ResetForm,
    AppLayout
  },
  data() {
    return {};
  }
};
</script>