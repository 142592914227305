import BaseApi from "./_base-api";

/**
 * Program API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	register(user) {
		return this.api()
			.post(this.getUrl("signup", "signup", null, "api"), user)
			.catch(error => {
				this.log().error("[Services: Register: Register]:", error);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Register",
	basePath: "api",
	path: ""
});

export default api;
