<template>
  <AppLayout>
    <VContainer
      id="register-page"
      px-0
      py-0
      fluid
    >
      <VRow
        align-center
        style="overflow-y: auto"
        justify-center
      >
        <VCol
          cols="12"
          md="8"
          lg="6"
        >
          <RegisterForm />
        </VCol>
      </VRow>
    </VContainer>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AlternateAppLayout";
import RegisterForm from "@/components/Register";

export default {
  name: "Register",
  components: {
    RegisterForm,
    AppLayout
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
/** extra imports **/
@import "../styles/variables.scss";
</style>