import api from "../../services/user";
export default function (helper) {
  helper.setApi(api);
  const TYPES = {
    USER_REQUEST: "USER_REQUEST",
    USER_ERROR: "USER_ERROR",
    USER_SUCCESS: "USER_SUCCESS",
    AUTH_REMEMBER: "AUTH_REMEMBER",
    USER_NOTIFICATIONS: "USER_NOTIFICATIONS",
    USER_GET_ONLY_NOTIFICATIONS: "USER_GET_ONLY_NOTIFICATIONS",
    USER_GET_ONLY_ANNOUNCEMENTS: "USER_GET_ONLY_ANNOUNCEMENTS",
    USER_ADD_NOTIFICATION: "USER_ADD_NOTIFICATION",
    USER_MARK_NOTIFICTIONS_READ: "USER_MARK_NOTIFICTIONS_READ",
    UPDATE_TEAM: "UPDATE_TEAM",
    USER_SET_AUTH_TOKEN: "USER_SET_AUTH_TOKEN",
    USER_GET_CONNECTED_ACCOUNTS: "USER_GET_CONNECTED_ACCOUNTS",
    USER_GET_CONNECTED_ACCOUNT: "USER_GET_CONNECTED_ACCOUNT",
    USER_DELETE_CONNECTED_ACCOUNT: "USER_DELETE_CONNECTED_ACCOUNT",
    USER_APPEND_NOTIFICATION_DATA: "USER_APPEND_NOTIFICATION_DATA",
  };

  const state = helper.state({
    appendData: false,
    needsUser: false,
    needsTeam: false,
    hasUser: false,
    isTeamOwner: false,
    fixture: {},
    notifications: {
      appendData: false,
      notifications: [],
      announcements: [],
    },
    connected_accounts: [],
  });

  const getters = helper.getters({
    team: (state) => {
      let team = state.data.team;
      if (!team instanceof Object) {
        team = state.date.teams.find(
          (t) => (t.id = state.data.current_team_id)
        );
        state.data.team = team;
      }
      return team;
    },
    teams: (state) => state.data.teams,
    notifications: (state) => state.notifications,
    isProfileLoaded: (state) => state.data && !!state.data.name,
    hasConnectedAccounts: (state) => state.connected_accounts.length > 0,
  });

  const actions = helper.actions(
    {
      loadFromCache: (context) => {
        return new Promise((resolve, reject) => {
          let user = JSON.parse(window.localStorage.getItem("user"));
          if (user instanceof Object && user.id) {
            user = prepareUser(user);
            if (user.spark && user.spark.billable) {
              context.dispatch("billing/updateBillable", user.spark.billable, {
                root: true,
              });
            }
            context.commit(TYPES.USER_SUCCESS, user);
            resolve(user);
          } else {
            reject(null);
          }
        });
      },
      setMe: (context, data) => {
        let user = prepareUser(data);
        context.commit(TYPES.USER_SUCCESS, user);
        if (user.spark && user.spark.billable) {
          context.dispatch("billing/updateBillable", user.spark.billable, {
            root: true,
          });
        }
      },
      me: (context) => {
        return helper
          .api()
          .me()
          .then((resp) => {
            let user = prepareUser(resp.data.data);
            if (user.spark && user.spark.billable) {
              context.dispatch("billing/updateBillable", user.spark.billable, {
                root: true,
              });
            }
            if (user instanceof Object && user.id) {
              context.commit(TYPES.USER_SUCCESS, user);
              return user;
            } else {
              context.dispatch("auth/logout", null, {
                root: true,
              });
            }
          })
          .catch((err) => {
            context.commit(TYPES.USER_ERROR, err);
            // If the user is saved in local storage then load them from there
            let user = JSON.parse(window.localStorage.getItem("user"));
            if (user instanceof Object && user.id) {
              user = prepareUser(user);
              if (user.spark && user.spark.billable) {
                context.dispatch(
                  "billing/updateBillable",
                  user.spark.billable,
                  {
                    root: true,
                  }
                );
              }
              context.commit(TYPES.USER_SUCCESS, user);
              return user;
            } else {
              context.dispatch("auth/logout", null, {
                root: true,
              });
            }
          });
      },
      getNotifications(context, params) {
        helper.log().info("[Store: Users]: Get Notifications", params);
        return helper
          .api()
          .getNotifications(params)
          .then((response) => {
            context.commit(TYPES.USER_NOTIFICATIONS, response.data.data);
            return response.data.data;
          })
          .catch((error) => {
            helper
              .log()
              .error("[Store: User: Get Notifications]: Error", error);
            throw helper.utils.resolveError(error);
          });
      },
      setAppendsNotificationData(context, value) {
        return context.commit(TYPES.USER_APPEND_NOTIFICATION_DATA, value);
      },
      getOnlyNotifications(context, params) {
        helper.log().info("[Store: Users]: Get Only Notifications", params);
        return helper
          .api()
          .getOnlyNotifications(params)
          .then((response) => {
            context.commit(
              TYPES.USER_GET_ONLY_NOTIFICATIONS,
              response.data.data
            );
            return response.data.data;
          })
          .catch((error) => {
            helper
              .log()
              .error("[Store: User: Get Only Notifications]: Error", error);
            throw helper.utils.resolveError(error);
          });
      },
      getOnlyAnnouncements(context, params) {
        helper.log().info("[Store: Users]: Get Only Announcements", params);
        return helper
          .api()
          .getOnlyAnnouncements(params)
          .then((response) => {
            context.commit(
              TYPES.USER_GET_ONLY_ANNOUNCEMENTS,
              response.data.data
            );
            return response.data.data;
          })
          .catch((error) => {
            helper
              .log()
              .error("[Store: User: Get Only Announcements]: Error", error);
            throw helper.utils.resolveError(error);
          });
      },
      addNotification(context, notification) {
        helper.log().info("[Store: Users]: Add Notification", notification);
        context.commit(TYPES.USER_ADD_NOTIFICATION, notification);
      },
      updateLastReadAnnouncements(context, params) {
        helper
          .log()
          .info("[Store: Users]: Update Last Read Notifications", params);
        return helper
          .api()
          .updateLastReadAnnouncements(params)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            helper
              .log()
              .error(
                "[Store: User: Update Last Read Notifications]: Error",
                error
              );
            throw helper.utils.resolveError(error);
          });
      },
      markNotificationsRead(context, params) {
        helper.log().info("[Store: Users]: Mark Notifications Read", params);
        return helper
          .api()
          .markNotificationsRead(params)
          .then((response) => {
            context.commit(TYPES.USER_MARK_NOTIFICTIONS_READ);
            return response;
          })
          .catch((error) => {
            helper
              .log()
              .error("[Store: User: Mark Notifications Read]: Error", error);
            throw helper.utils.resolveError(error);
          });
      },
      save(context, params) {
        helper.log().info("[Store: Users]: SaveUser", params);
        return helper
          .api()
          .save(params)
          .then((response) => {
            context.commit(TYPES.USER_SUCCESS, response.data.data);
            return response;
          })
          .catch((error) => {
            helper.log().error("[Store: User: SaveUser]: Error", error);
            throw helper.utils.resolveError(error);
          });
      },
      setCurrentTeam(context, params) {
        if (params instanceof Object || params !== undefined) {
          helper.log().info("[Store: Users]: SetCurrentTeam", params);
          return helper
            .api()
            .setCurrentTeam(params)
            .then((result) => {
              context.commit(TYPES.UPDATE_TEAM, result.data);
              return result.data.data;
            })
            .catch((error) => {
              helper.log().error("[Store: User: SetCurrentTeam]: Error", error);
              throw helper.utils.resolveError(error);
            });
        } else {
          return Promise.resolve({
            success: false,
          });
        }
      },
      setAuthToken(context, params) {
        helper.log().info("[Store: User]: Set Auth Token", params);
        return helper
          .api()
          .setAuthToken(params)
          .then((response) => {
            helper.log().info(`[Store: User]: Set Auth Token`, response);
            const data = response.data.data;
            context.commit(TYPES.USER_SET_AUTH_TOKEN, data);
            return data;
          })
          .catch((error) => {
            helper.log().info(`[Store: User]: Error Setting Auth Token`, error);
            throw error;
          });
      },
      async getConnectedAccounts(context, params) {
        helper.log().info("[Store: User]: Get Connected Accounts", params);
        return await helper
          .api()
          .getConnectedAccounts(params)
          .then((response) => {
            helper
              .log()
              .info(`[Store: User]: Got Connected Accounts`, response);
            const data = response.data.data;
            context.commit(TYPES.USER_GET_CONNECTED_ACCOUNTS, data);
            return data;
          })
          .catch((error) => {
            helper
              .log()
              .info(`[Store: User]: Error Get Connected Accounts`, error);
            throw error;
          });
      },
      async getConnectedAccount(context, type) {
        helper.log().info("[Store: User]: Get Connected Account", type);
        let account =
          context.getters.hasConnectedAccounts &&
          context.state.connected_accounts
            .filter((e) => e instanceof Object)
            .find((existing) => existing.slug === type);
        if (account instanceof Object) {
          helper
            .log()
            .info(`[Store: User]: Got Local Connected Account`, account);
          return account;
        } else {
          return await helper
            .api()
            .getConnectedAccount(type)
            .then((response) => {
              helper
                .log()
                .info(`[Store: User]: Got Connected Account`, response);
              const data = response.data.data;
              context.commit(TYPES.USER_GET_CONNECTED_ACCOUNT, data);
              return data;
            })
            .catch((error) => {
              helper
                .log()
                .info(`[Store: User]: Error Get Connected Accounts`, error);
              throw error;
            });
        }
      },
      async deleteConnectedAccount(context, account) {
        helper
          .log()
          .info("[Store: User]: Delete Connected Account", account.slug);
        return await helper
          .api()
          .deleteConnectedAccount(account.slug)
          .then((response) => {
            helper
              .log()
              .info(`[Store: User]: Delete Connected Account`, response);
            const data = response.data.data;
            context.commit(TYPES.USER_DELETE_CONNECTED_ACCOUNT, {
              params: account.id,
              result: data,
            });
          })
          .catch((error) => {
            helper
              .log()
              .info(`[Store: User]: Error Delete Connected Accounts`, error);
            throw error;
          });
      },
    },
    "user",
    true
  );

  const mutations = {
    [TYPES.USER_REQUEST]: (state, payload) => {
      state.status = "loading";
      state.user_token = payload;
    },

    [TYPES.USER_SUCCESS]: (state, user) => {
      state.status = "success";
      state.isTeamOwner = user && user.team && user.id === user.team.owner_id;
      state.needsTeam = !user.current_team_id;
      state.hasUser = user.teams && user.teams.length > 0;
      state.data = { ...(state.data || {}), ...user };
      window.localStorage.setItem("user", JSON.stringify(user));
    },
    [TYPES.USER_ERROR]: (state) => {
      state.status = "error";
    },
    [TYPES.USER_NOTIFICATIONS]: (state, notifications) => {
      state.notifications = {
        ...state.notifications,
        ...notifications,
      };
    },
    [TYPES.USER_APPEND_NOTIFICATION_DATA]: (state, value) => {
      state.notifications.appendData = !!value;
    },
    [TYPES.USER_GET_ONLY_NOTIFICATIONS]: (state, notifications) => {
      if (state.notifications.notifications.data instanceof Array) {
        state.notifications.notifications = {
          ...notifications,
          data: state.notifications.appendData
            ? state.notifications.notifications.data
                .concat(notifications.data)
                .unique((n) => n.id)
            : notifications.data,
        };
      } else {
        state.notifications.notifications = state.notifications.appendData
          ? state.notifications.notifications
              .concat(notifications)
              .unique((n) => n.id)
          : notifications;
      }
    },
    [TYPES.USER_GET_ONLY_ANNOUNCEMENTS]: (state, announcements) => {
      if (state.announcements.announcements.data instanceof Array) {
        state.announcements.announcements = {
          ...announcements,
          data: state.notifications.appendData
            ? state.announcements.announcements.data
                .concat(announcements.data)
                .unique((n) => n.id)
            : announcements.data,
        };
      } else {
        state.announcements.announcements = state.notifications.appendData
          ? state.announcements.announcements
              .concat(announcements)
              .unique((n) => n.id)
          : announcements;
      }
    },
    [TYPES.USER_ADD_NOTIFICATION]: (state, notification) => {
      state.notifications.notifications.push(notification);
      if (state.notifications.notifications.data instanceof Array) {
        state.notifications.notifications.data.push(notification);
      } else {
        state.notifications.notifications.push(notification);
      }
    },
    [TYPES.USER_MARK_NOTIFICTIONS_READ]: (state) => {
      if (state.notifications.notifications.data instanceof Array) {
        state.notifications.notifications.data = state.notifications.notifications.data.map(
          (notification) => {
            notification.read = 1;
            return notification;
          }
        );
      } else {
        state.notifications = state.notifications.notifications.map(
          (notification) => {
            notification.read = 1;
            return notification;
          }
        );
      }
    },
    [TYPES.UPDATE_TEAM]: (state, team) => {
      if (state.data.team.id == team.id) {
        state.data.team = team;
      }
      const index = state.data.teams.findIndex(
        (existing) => existing.id === team.id
      );
      if (index > -1) {
        state.data.teams.splice(index, 1, team);
      }
      window.localStorage.setItem("user", JSON.stringify(state.data));
    },
    [TYPES.USER_SET_AUTH_TOKEN](state, data) {},
    [TYPES.USER_GET_CONNECTED_ACCOUNTS](state, data) {
      state.connected_accounts = data;
    },
    [TYPES.USER_DELETE_CONNECTED_ACCOUNT](state, data) {
      helper.utils.removeFromStateData(
        state.connected_accounts,
        data.params,
        true
      );
    },
    [TYPES.USER_GET_CONNECTED_ACCOUNT](state, data) {
      helper.utils.addToStateData(state.connected_accounts, data, true);
    },
  };

  const prepareUser = function (user) {
    const team = resolveTeam(user);
    if (!user.role_name) {
      user.role_name = user.role ? user.role.id.titleize() : null;
      user.role_name = !user.role_name
        ? state.isTeamOwner
          ? "Owner"
          : user.role_name
        : user.role_name;
    }
    if (!user.role_slug) {
      user.role_slug = user.role ? user.role.id : null;
      user.role_slug = !user.role_slug
        ? state.isTeamOwner
          ? "owner"
          : user.role_slug
        : user.role_slug;
    }
    user.team = team || user.team;
    return user;
  };

  const resolveTeam = function (user) {
    try {
      if (user) {
        const team = (helper.app().$route.params.team || "").slugify();
        let userTeam = user.team || {
          slug: "-",
        };
        if (!team instanceof Object) {
          if (user.teams instanceof Array) {
            userTeam =
              user.teams instanceof Array
                ? user.teams.find(
                    (model) => model.id === user.current_team_id
                  ) || user.teams[0]
                : userTeam;
          }
        } else if (userTeam instanceof Object && !userTeam.id) {
          userTeam =
            user.teams instanceof Array
              ? user.teams.filter((model) => model.slug === team)[0] ||
                user.teams[0]
              : userTeam;
        }
        helper.log().debug("User Team", userTeam);
        return userTeam;
      } else {
        return {};
      }
    } catch (e) {
      helper.log().error("Resolve Team", e);
    }
  };

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
}
